import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
// import DevLogin from "../views/DevLogin.vue";
import HomePage from "../views/HomePage.vue";
import lobby from "../views/lobby.vue";
import Privacy from "../views/Privacy.vue";
import MagicPicture from "../views/magicPicture.vue";
import FastestFingerFirst from "../views/fastestFingerFirst.vue";
import EventInfo from "../views/EventInfo.vue";
import MagicPictureInfoPage from "../views/MagicPictureInfoPage.vue";
import FFFInfoPage from "../views/FFFInfoPage.vue";
import ScribbleInfoPage from "../views/ScribbleInfoPage.vue";
import Meditation from "../views/MeditationPage.vue";
import GamesDashboard from "../views/GamesDashboard.vue";
import ScribleGame from "../views/scribleGame.vue";
import Marketplace from "../views/Marketplace.vue";
import ResetPassword from "../views/ResetPassword.vue";
import CreateActivity from "../views/CreateActivity.vue";
import CustomActivity from "../views/customActivity.vue";
import ActivityInfo from "../views/ActivityInfoPage.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import JoinRoom from "../views/JoinRoom.vue";
import NotFound from "../components/NotFound/NotFound.vue";
import store from "../store";
import TMInterview from "../views/TMInterview.vue";
import CodeSolvingPage from "../views/CodeSolvingPage.vue";
import InterviewInfo from "../views/InterviewInfo.vue";
import JoinInterview from "../views/JoinInterview.vue";
import ParticipantInterviewInfo from "../views/ParticipantInterviewInfo.vue";
import InterviewHome from "../views/TmInterviewHome.vue";
import EngagementHome from "../views/TMEngagementHome.vue";
import TimesupPage from "../views/TimesupPage.vue";


Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Privacy,
        meta: { requireAuth: false },
    },
    {
        path: "/Contact",
        name: "Contact",
        component: Contact,
        meta: { requireAuth: false }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requireAuth: false },
    },
    // {
    //     path: "/tm-bypass/login",
    //     name: "DevLogin",
    //     component: DevLogin,
    // },
    {
        path: "/lobby",
        name: "lobby",
        component: lobby,
        meta: { requireAuth: true }
    },
    {
        path: "/homepage",
        name: "HomePage",
        component: HomePage,
        meta: { requireAuth: true },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/marketplace",
        name: "Marketplace",
        component: Marketplace,
        meta: { requireAuth: true },
    },
    {
        path: "/magicpicture",
        name: "MagicPicture",
        component: MagicPicture,
    },
    {
        path: "/magicpictureinfo",
        name: "MagicPictureInfo",
        component: MagicPictureInfoPage,
        meta: { requireAuth: true },
    },
    {
        path: "/scribbleinfo",
        name: "ScribbleInfo",
        component: ScribbleInfoPage,
        meta: { requireAuth: true },
    },
    {
        path: "/fffinfo",
        name: "FFFInfo",
        component: FFFInfoPage,
        meta: { requireAuth: true },
    },
    {
        path: "/eventinfo",
        name: "EventInfo",
        component: EventInfo,
        meta: { requireAuth: true },
    },
    {
        path: "/fastestfingerfirst",
        name: "FastestFingerFirst",
        component: FastestFingerFirst,
    },
    {
        path: "/scriblegame",
        name: "ScribleGame",
        component: ScribleGame,
    },
    {
        path: "/meditation",
        name: "Meditation",
        component: Meditation,
        meta: { requireAuth: true },
    },
    {
        path: "/gamesdashboard",
        name: "GamesDashboard",
        component: GamesDashboard,
        meta: { requireAuth: true },
    },
    {
        path: "/create-activity",
        name: "CreateActivity",
        component: CreateActivity,
        meta: { requireAuth: true, showSidebar:false },
    },
    {
        path: "/CustomActivity",
        name: "CustomActivity",
        component: CustomActivity
    },
    {
        path: "/About",
        name: "About",
        component: About,
        meta: { requireAuth: false }
    },
    {
        path: "/activity-info",
        name: "ActivityInfo",
        component: ActivityInfo,
        meta: { requireAuth: true },
    },
    {
        path: "/join/:roomId",
        name: "JoinRoom",
        component: JoinRoom
    },
    {
        path: "/problems/:Id",
        name: "Problems",
        component: CodeSolvingPage,
        meta: {  showSidebar:false  }
    },
    {
        path: "/interviews",
        name: "interviews",
        component: TMInterview,
        meta: { requireAuth: true }
    },
    {
        path: "/interview/:Id",
        name: "InterviewInfo",
        component: InterviewInfo,
        meta: { requireAuth: true }
    },
    {
        path: "/tmInterview/:Id",
        name: "JoinInterview",
        component: JoinInterview
    },
    {
        path: "/participantInterview/:Id",
        name: "ParticipantInterviewInfo",
        component: ParticipantInterviewInfo,
        meta: { showSidebar:false }
    },
    {
        path: "/tm-interview",
        name: "InterviewHome",
        component: InterviewHome
    },
    {
        path: "/tm-engagement",
        name: "EngagementHome",
        component: EngagementHome
    },
    {
        path: "/timesupPage",
        name: "TimesupPage",
        component: TimesupPage
    },
    {
        path: '*',
        component: NotFound,
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (to.meta.requireAuth) {
        if (!store.state.isLoggedIn && !store.state.skipAuth) {
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }
    next();
});
export default router;