<template>
  <div class="join-room-container join-inter-bg">
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div v-if="invalidRoom" class="invalid-room">{{ errMsg }}</div>
    <b-card v-else class="join-room-card" border-variant="primary">

    <div v-if="stream" style="text-align: center;">
      <video ref="video" autoplay playsinline muted></video>
      <p>Camera and microphone are enabled!</p>
    </div>
    <div v-else style="text-align: center;color: red;">
      <p>Permission is required to access the camera and microphone.</p>
    </div>
      <b-form @submit="submit">
        <b-row class="my-1">
          <b-col>
            <label for="input-none" class="form-label">Full Name:</label>
            <b-form-input
              id="input-none"
              v-model="form.userName"
              type="text"
              :state="form.userName.length > 0 ? true : null"
              placeholder="Enter full name"
              required
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <label for="input-none" class="form-label">Email:</label>
            <b-form-input
              id="input-none"
              v-model="form.email"
              type="text"
              :state="form.email.length > 0 ? true : null"
              placeholder="Enter email"
              required
            ></b-form-input>
          </b-col>
        </b-row>

        <p v-if="isInvalid" style="color: red; text-align: center">
          {{ errMsg }}
        </p>
        <b-button
          pill
          class="mt-3 btn-layout-2 color-2"
          variant="primary"
          block
          size="lg"
          type="submit"
          >Register</b-button
        >
      </b-form>
    </b-card>
    <b-modal
      id="error-modal"
      ref="error-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title="Oops."
    >
      <p class="my-4">{{ errMsg }}. Please try again</p>
    </b-modal>
  </div>
</template>

<script>
import { getInterviewBasicData,createCandidateRegistration } from "../api/index";
export default {
  data() {
    return this.initialState();
  },
  mounted() {
    this.getJoinInterviewDetails();

    // Request camera and microphone access on page load
    this.requestPermissions();
  },
  computed: {

  },
  methods: {
    initialState() {
      return {
        invalidRoom:false,
        loading: false,
        loadingMsg: "Please Wait...",
        isInvalid: false,
        errMsg: "",
        interviewData:{},
        form: {
          userName: "",
          email: "",
          roomCode: this.$route.params.Id,
        },

        stream: null // To hold the media stream
      };
    },
    resetData() {
      Object.assign(this.$data, this.initialState());
    },
    getJoinInterviewDetails() {
      this.loading = true;
      const payload ={
        Id:this.$route.params.Id
      }
      getInterviewBasicData(payload)
        .then((resp) => {
          if (resp && resp.data && resp.data.data) {
            this.interviewData = resp.data.data;
            this.loading = false;
          } 
        })
        .catch((err) => {
          console.log("Error",err);
          this.errMsg =err.response.data.message;
          this.invalidRoom = true;
          this.loading = false;
        });
    },
    submit(event) {
      event.preventDefault();
      if(!this.stream){
        return;
      }
      const payload ={
        InterviewId:this.interviewData.Id,
        FullName: this.form.userName,
        Email:this.form.email,
        CandidateDetails:"",
        Status:"Registered"
      }
      createCandidateRegistration(payload)
        .then((resp) => {
          if (resp && resp.data && resp.data.data) {
            this.$store.commit("setLoggedInCandidteInfo", resp.data.data);
            this.$store.commit("setInterviewId", this.interviewData.Id);
            this.$router.push(`/participantInterview/${this.interviewData.Id}`)
            this.loading = false;
          } 
        })
        .catch((err) => {
          console.log("Error",err);
          this.errMsg =err.response.data.message;
          this.invalidRoom = true;
          this.loading = false;
        });
    },
    validateData() {
        return true;
    },


    async requestPermissions() {
      try {
       // Request permissions for video (front camera) and audio
       const constraints = {
          video: { facingMode: "user" }, // Use "environment" for the back camera
          audio: true,
        };

        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

        console.log("Stream assigned to video element:", mediaStream);
        // Assign the stream to the data property
        this.stream = mediaStream;

        // Wait for the DOM to update before accessing $refs
        this.$nextTick(() => {
          const videoElement = this.$refs.video;
          if (videoElement) {
            videoElement.srcObject = mediaStream;
            videoElement.play(); // Ensure the video starts playing
          }
        });
      } catch (error) {
        console.error("Permission denied or an error occurred:", error);
      }
    }
  },
};
</script>

<style scoped>
video {
  width: 300px;
  max-height: 300px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background: black;
  text-align: center;
}
.join-room-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, var(--primary-color) 0%, #2a5298 100%);
  background-image: url("../assets/join_bg1.png");
  background-size: cover;   
}

.join-inter-bg{
  background-image: url("../assets/join_inter_bg2.png");
}
.join-room-card {
  min-width: 40vw;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.b-dropdown .dropdown-menu {
  width: 100%;
}

.b-dropdown-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.b-dropdown-item:hover {
  background-color: #e9ecef;
}
.invalid-room{
    color: white;
    font-weight: bold;
    font-size: 50px;
}
</style>
