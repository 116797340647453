var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-layout h-100vh bg-primary-gradient"},[(_vm.loading)?_c('BlockUI',{attrs:{"message":_vm.loadingMsg}},[_c('b-icon',{attrs:{"icon":"three-dots","animation":"cylon","font-scale":"4"}})],1):_vm._e(),(_vm.isAssessmentStarted)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"timer"},[_c('p',[_vm._v("Time Left: "+_vm._s(_vm.formattedTimer))])]),_c('div',[_c('div',{staticClass:"SectionTitle text-center text-xl"},[_vm._v(_vm._s(_vm.interviewData.Title))])]),_c('div',{staticClass:"d-flex my-2 mb-4 align-items-center justify-content-center text-md"},[_c('div',{},[_vm._v("Position :")]),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.interviewData.Position))])]),_c('div',{staticClass:"codeProblem-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4"},_vm._l((_vm.interviewData.problems_Data),function(item,index){return _c('div',{key:index,staticClass:"codeProblem-card transform transition hover:scale-105"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"item-title font-semibold w-full",attrs:{"title":item.ProblemTitle}},[_vm._v(" "+_vm._s(item.ProblemTitle)+" ")]),_c('div',{staticClass:"w-full d-flex justify-content-between mt-2 align-items-center"},[_c('span',{staticClass:"difficulty",class:{
              'difficulty-easy': item.ProblemDifficulty === 'Easy',
              'difficulty-medium': item.ProblemDifficulty === 'Medium',
              'difficulty-hard': item.ProblemDifficulty === 'Hard',
            }},[_vm._v(" "+_vm._s(item.ProblemDifficulty)+" ")]),_c('span',{class:{
                'difficulty-easy': item.Status=='Submitted',
                'difficulty-hard':  item.Status!='Submitted',
              }},[_vm._v(" "+_vm._s(item.Status)+" ")])]),_c('button',{staticClass:"submitButton finishButton px-2 py-2 mr-2 mt-3 w-full",attrs:{"disabled":item.Status=='Submitted'},on:{"click":function($event){return _vm.handleStartClick(item)}}},[_vm._v(" Start ")])])])}),0)]):_vm._e(),_c('DynamicConfirmation',{attrs:{"msg":_vm.guidelinesMsg,"msg_title":_vm.guidelinesTitle},on:{"confirmed":function($event){return _vm.handleStart(_vm.item)}}}),_c('b-modal',{ref:"error-modal",attrs:{"id":"error-modal","size":"sm","button-size":"sm","header-class":"p-2 border-bottom-0","footer-class":"p-2 border-top-0","hide-header-close":"","ok-only":"","centered":"","title":"Oops."}},[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.errMsg))])]),_c('b-modal',{ref:"error-modal",attrs:{"id":"start-Interview-info-modal","size":"md","no-close-on-backdrop":"","hide-header-close":"","buttonSize":"md","headerClass":"p-2 border-bottom-0","footerClass":"p-2 border-top-0","hide-footer":"","centered":"","title":"Assessment Information"}},[_c('p',{staticClass:"my-4"},[_vm._v("This is a time based Interview you will get 60 minutes + 20 minutes Extra (Java compilation) to complete this assessment. Refreshing or switching tabs will lead to auto submit assessment. NOTE : Each compile/Run takes 20-30 sec so be patient.")]),_c('b-button',{staticClass:"mt-3",attrs:{"block":""},on:{"click":_vm.startAssesment}},[_vm._v("Start")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }