<template>
    <div class="page-layout h-100vh bg-primary-gradient">
        <div class="container">
            <h1>Time's Up!</h1>
            <p>
                Thank you for participating in the interview. We appreciate your time and effort.
                Our team will review your submission, and we will get back to you if you are shortlisted.
            </p>
            <button @click="goToHome" class="button">Back to Home</button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToHome() {
            this.$router.push('/'); // Navigate to the home page
        },
    },
};
</script>

<style scoped>
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.container {
    text-align: center;
    max-width: 600px;
    padding: 2rem;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: -webkit-linear-gradient(#fff, #ddd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #f8f8f8;
    line-height: 1.6;
}

.button {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #6a11cb;
    background: #fff;
    border: none;
    border-radius: 30px;
    text-decoration: none;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(255, 255, 255, 0.3);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
</style>